<template>
    <b-sidebar  v-model="show" backdrop backdrop-variant="light" width="531px" bg-variant="white" right no-header-close>
        <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-variant="primary"
            >
            <div class="p-24 ">
                <div class="d-flex">
                    <app-heading-3>Project Overview</app-heading-3>
                    <feather-icon icon="XIcon" size="20" class="cursor-pointer ml-auto"  @click="show = false"></feather-icon>
                </div>
            </div>
            <div class="p-24">
                <div class="row">
                    <div class="col-12 d-flex mb-12">
                        <app-heading-3>Project Details</app-heading-3>
                        <feather-icon icon="EditIcon" v-if="getUserRole=='service_provider'" size="20" class="cursor-pointer ml-auto text-primary"  @click="editProjectDetails()"></feather-icon>
                    </div>
                    <div class="col-md-6 col-12 mb-12">
                        <app-heading-4 class="d-flex align-items-center ">Project Name: </app-heading-4>
                        <app-normal-text class="">{{ project_data.name }}</app-normal-text>
                    </div>
                    <div class="col-md-6 col-12 mb-12">
                        <app-heading-4 class="d-flex align-items-center">Total Contract Value: </app-heading-4>
                        <app-normal-text class="">{{project_data.estimatedProjectAmount | currency}}</app-normal-text>
                    </div>
                    <div class="col-12 mb-12">
                        <app-heading-4 class="d-flex align-items-center">Scope Of Work: </app-heading-4>
                        <app-normal-text class="">{{project_data.scopeOfWork}}</app-normal-text>
                    </div>
                    <div class="col-md-6 col-12 ">
                        <app-heading-4 class="d-flex align-items-center">Total Amount Recieved: </app-heading-4>
                        <app-normal-text class="">{{project_data.amountReceived | currency}}</app-normal-text>
                    </div>
                    <div class="col-md-6 col-12">
                        <app-heading-4 class="d-flex align-items-center">Total Balance Due: </app-heading-4>
                        <app-normal-text class="">{{project_data.totalBalanceDue | currency}}</app-normal-text>
                    </div>
                </div>
            </div>
            <div class="p-24 border-0">
                <div class="row">
                    <div class="col-12">
                        <app-heading-3>Collaborators</app-heading-3>
                        <div class="mb-1" v-if="project_data.collaboratorEmails.length>0">
                            <div class="collaborator-card d-flex align-items-center mt-1" v-for="(c,index) in project_data.collaboratorEmails" :key="index">
                                <div class="email-avatar">{{c.email.slice(0,1).toUpperCase()}}</div>
                                <div>
                                    <p class="text-small mb-0">{{getSlicedEmail(c.email)}}</p>
                                </div>
                                <feather-icon icon="TrashIcon" size="22" v-if="getUserRole=='service_provider'" class="ml-auto cursor-pointer text-danger" @click="deleteCollaborator(c._id)"></feather-icon>
                            </div>
                            <b-button variant="primary" class="mt-1"   @click="()=>add=true" v-if="!add && getUserRole=='service_provider'">Add More Collaborators</b-button>
                        </div>
                        <div v-if="add || project_data.collaboratorEmails.length<1">
                            <div >
                                <app-text-input   label="Email Address" v-model="collaborator_details.collaboratorEmail" @onKeyup="fetchCollaburators" placeholder="jhon.doe@gmail.com"/>
                                <div class="sugestion-list" v-if="!hide_suggestions && suggestions.length">
                                    <div class="suggestion" v-for="(s,index) in suggestions" :key="index" @click="selectSuggetion(s)">{{s.email}}</div>
                                </div>
                            </div>
                            <b-overlay
                                :show="loading"
                                rounded
                                opacity="0.6"
                                spinner-small
                                spinner-variant="primary"
                                class="d-inline-block "
                            >
                                <b-button variant="primary" :disabled="!collaborator_details.collaboratorEmail" @click="addCollaborator()">Add</b-button>
                            </b-overlay>
                        </div>
                    </div>
                    
                </div>
            </div>
        </b-overlay>
        <app-confirm-modal ref="confirmModal" heading="Remove Collaborator" cancelText="Not Now" confirmText="Yes Remove" @onConfirm="removeCollaburator()">Are you sure, you want to remove this collaborator from this project?</app-confirm-modal>
        <edit-project-details ref="editProjectDetails" @onUpdated="getProjectDetails(project_data.id)"/>
        <!-- <div class="d-flex p-24 border-0 button-bottom w-100">
            <app-simple-button variant="primary" @click="()=>show=false">Cancel</app-simple-button>
        </div> -->
    </b-sidebar>
</template>
<script>
import AppHeading3 from '@/components/app-components/AppHeading3.vue'
import AppHeading2 from '@/components/app-components/AppHeading2.vue'
import AppHeading4 from '@/components/app-components/AppHeading4.vue'
import AppNormalText from '@/components/app-components/AppNormalText.vue'
import AppTextInput from '@/components/app-components/AppTextInput.vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
import AppConfirmModal from '@/components/app-components/AppConfirmModal.vue';
import {search_user_by_email,add_collaborator_to_project, remove_collaborator_from_project, get_project_details} from '@/apis/others/documents'
import {BSidebar, BOverlay, BButton} from 'bootstrap-vue'
import EditProjectDetails from '@/components/functional-components/other/documents/EditProjectDetails.vue'
import {getUserData} from '@/auth/utils'
export default {
    components: {
        AppHeading3,
        BSidebar,
        AppHeading2,
        AppHeading4,
        AppNormalText,
        AppSimpleButton,
        BOverlay,
        BButton,
        AppTextInput,
        AppConfirmModal,
        EditProjectDetails
    },
    data(){
        return{
            show:false,
            project_data:{
                name:'',
                collaboratorEmails:[],
                estimatedProjectAmount:null,
                amountReceived:null,
                totalBalanceDue:null,
                scopeOfWork:'',
                id:null,
            },
            collaborator_details:{
                collaboratorEmail:''
            },
            suggestions:[],
            currentCollaborator:'',
            hide_suggestions:true,
            add:false,
            loading:false
        }
    },
    computed:{
        getUserRole(){
            const user=getUserData()
            return user.role
        },
    },
    methods:{
       showDetails(id){
            // this.userDetails=details;
            this.collaborator_details.collaboratorEmail=''
            this.show=true;
            this.add=false;
            this.getProjectDetails(id)
            // this.getUserInfo(details._id)
       },
       getProjectDetails(id){
            this.loading=true;
            get_project_details(id).then(resp=>{
                    const {name, estimatedProjectAmount,scopeOfWork,collaborators,_id}=resp.data.folderDetails
                    this.project_data.name=name
                    this.project_data.estimatedProjectAmount=estimatedProjectAmount
                    this.project_data.scopeOfWork=scopeOfWork
                    this.project_data.collaboratorEmails=collaborators
                    this.project_data.amountReceived=resp.data.totalAmountReceived
                    this.project_data.totalBalanceDue=resp.data.totalBalanceDue
                    this.project_data.id=_id
                    this.loading=false;
                }
                
            )
        },
       toCapitalize(name){
        let words = name.split("_")
            let upperCaseStr= words.map(w=>`${w.charAt(0).toUpperCase()}${w.slice(1)}`).join(" ")
            return upperCaseStr
        }, 
        getSlicedEmail(email){
            return email.length <=20 ? email: email.slice(0,20)+'...'
        },
        selectSuggetion(s){
            this.collaborator_details.collaboratorEmail=s.email
            this.suggestions=[]
            this.hide_suggestions=true
        },
        fetchCollaburators(){
            search_user_by_email(this.collaborator_details.collaboratorEmail).then(resp=>{
                this.suggestions=resp.data.users
            })
            this.hide_suggestions=false
        },
        addCollaborator(){
            this.loading=true
            add_collaborator_to_project({id:this.project_data.id,data:this.collaborator_details}).then(resp=>{
                this.loading=false
                this.add=false
                this.project_data.collaboratorEmails=resp.data.collaborators
                this.collaborator_details.collaboratorEmail=''
            }).catch(err=>{
                this.loading=false
                this.$bvToast.toast(err.message, {
                        title: err.error,
                        variant: 'danger',
                        solid: true,
                    }) 
            })  
        },
        deleteCollaborator(id){
            this.currentCollaborator=id
            this.$refs.confirmModal.openModal()
        },
        removeCollaburator(){
            remove_collaborator_from_project({projectId:this.project_data.id,id:this.currentCollaborator}).then(resp=>{
                this.$refs.confirmModal.hideModal()
                this.getProjectDetails(this.project_data.id)
            }).catch(err=>{
                this.$refs.confirmModal.hideModal()
                this.$bvToast.toast(err.message, {
                        title: err.error,
                        variant: 'danger',
                        solid: true,
                    }) 
            })
        },
        editProjectDetails(){
            this.$refs.editProjectDetails.showModal(this.project_data)
        }

    }
}
</script>
<style >
.p-24{
    padding: 24px;
    border-bottom: 1px solid #DBDADE;
}
.ml-8{
    margin-left: 8px;
}
.mb-12{
    margin-bottom: 12px;
}
.button-bottom{
    position: absolute;
    bottom: 0;
}
@media(max-width:425px){
    .b-sidebar-outer{
        z-index: 10 !important;
    }
}
.sugestion-list{
    max-height: 100px;
    overflow-y: auto;
    margin-bottom: 8px;
    position: absolute;
}
.suggestion{
    cursor: pointer;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
}
.suggestion:hover{
    background-color: #ecf2ff;
}

.sugestion-list::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .sugestion-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgb(226, 225, 225);
    border-radius: 10px;
  }
  
  /* Handle */
  .sugestion-list::-webkit-scrollbar-thumb {
    background: #ecf2ff;
    border-radius: 5px;
  }
  .collaborator-card{
    padding: 12px;
    border-radius: 8px;
}
.email-avatar{
    text-align: center;
    vertical-align: middle;
    padding-top: 5px;
    height: 36px;
    width:36px;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 18px;
    font-family: 400;
    color: #000000;
    background-color: #a1f3ff;
}
.text-small{
    font-size:14px;
    font-weight: 400;
    line-height: 16px;
    color:#4B465C;
}
.collab-doc{
    height: 430px;
    overflow-y: auto;
}
.collab-doc::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .collab-doc::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .collab-doc::-webkit-scrollbar-thumb {
    background: lightgray; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .collab-doc::-webkit-scrollbar-thumb:hover {
    background: #004bff; 
  }
  @media(max-width:425px){
    .collab-doc{
        height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
    }
  }
</style>